@import "./styles/global.scss";
@import "./styles/fonts.css";
@import "./styles/colors.css";
@import "./styles/typography.css";

html * {
  /* font-family: "NotoSans", sans-serif; */
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden;
}

.App-header {
  background-color: var(--C8);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
