:root {
  /* Colors */
  --C1: #c3e3cb;
  --C2: #495a95;
  --C3: #d3a39a;
  --C4: #4e597b;
  --C5: #1f2f53;
  --C6: #edf7ef;
  --C7: #3daf99;
  --C8: #ffffff;
  --C9: #fcfcfd;
  --C10: #f3f4f6;
  --C11: #edeef2;
  --C12: #b8bdc8;
  --C13: #f0f8da;
  --C14: #f9f2d8;
  --C15: #ebf6fb;
  --C16: #ebeffb;
  --C17: #f5ebfb;
  --C18: #f4ebe4;
  --C19: #feeddc;
  --C20: #ebfbf7;
  --C21: #ffecff;
  --C22: #ffe9f1;
  --C23: #ebfbef;
  --C24: #d3c6c7;
  --C25: #f9fcfa;
  --C26: #424242;
  --C27: #edfae7;
  --C28: #faf9dc;
  --C29: #ffebec;
}

/* Tag Colors */
.tag-free {
  background-color: var(--C23);
}
.tag-cost {
  background-color: var(--C22);
}
.tag-lgbtqia {
  background-image: linear-gradient(to left, #daeff8, #e9f8da, #f8dada);
}
.tag-government_service {
  background-color: var(--C28);
}
.tag-counselling_available {
  background-color: var(--C20);
}
.tag-C13 {
  background-color: var(--C13);
}
.tag-C14 {
  background-color: var(--C14);
}
.tag-C15 {
  background-color: var(--C15);
}
.tag-C16 {
  background-color: var(--C16);
}
.tag-C17 {
  background-color: var(--C17);
}
.tag-C18 {
  background-color: var(--C18);
}
.tag-C19 {
  background-color: var(--C19);
}
.tag-C20 {
  background-color: var(--C20);
}
.tag-C21 {
  background-color: var(--C21);
}
.tag-C22 {
  background-color: var(--C22);
}
.tag-C23 {
  background-color: var(--C23);
}
.tag-C27 {
  background-color: var(--C27);
}
.tag-C29 {
  background-color: var(--C29);
}
