h1,
h2,
h3,
h4,
h5,
h6,
title,
p,
span {
  margin: 0;
  font-weight: 100;
}

a {
  text-decoration: none;
}

h1 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 130%;
}

h2,
.h2 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
}

h3 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
}

h4 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
}

h5 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
}

h6 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 200%;
}

.T1 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.T2 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
}

.T3 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
}

.T4 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.2em;
}

.T5 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
}

.T6 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: var(--C4);
  font-size: 16px;
}

.T7 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

.T8 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.T9 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
}

.T10 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.02em;
}

.B1 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 150%;
}

.B2 {
  font-family: "NotoSans Regular", "NotoSansSinhala Regular",
    "NotoSansTamil Regular", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
}

/* Mobile Font sizes */
@media screen and (max-width: 411px) {
  h1 {
    font-size: 24px;
  }
  h2,
  .h2 {
    font-size: 17px;
  }
  h3 {
    font-size: 17px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 17px;
  }
  h6 {
    font-size: 28px;
  }
  .T1 {
    font-size: 15px;
  }
  .T2 {
    font-size: 17px;
  }
  .T3 {
    font-size: 15px;
  }
  .T7 {
    font-size: 14px;
  }
  .T8 {
    font-size: 12px;
  }
  .T9 {
    font-size: 13px;
  }
  .B2 {
    font-size: 13px;
  }
}
