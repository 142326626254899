/* English */
@font-face {
  font-family: "NotoSans Regular";
  src: url(../fonts/English/NotoSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "NotoSans LightItalic";
  src: url(../fonts/English/NotoSans-LightItalic.ttf) format("truetype");
}
@font-face {
  font-family: "NotoSans SemiBold";
  src: url(../fonts/English/NotoSans-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "NotoSans Bold";
  src: url(../fonts/English/NotoSans-Bold.ttf) format("truetype");
}

/* Sinhala */
@font-face {
  font-family: "NotoSansSinhala Regular";
  src: url(../fonts/Sinhala/NotoSansSinhala-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "NotoSansSinhala SemiBold";
  src: url(../fonts/Sinhala/NotoSansSinhala-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "NotoSansSinhala Bold";
  src: url(../fonts/Sinhala/NotoSansSinhala-Bold.ttf) format("truetype");
}

/* Tamil */
@font-face {
  font-family: "NotoSansTamil Regular";
  src: url(../fonts/Tamil/NotoSansTamil-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "NotoSansTamil SemiBold";
  src: url(../fonts/Tamil/NotoSansTamil-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "NotoSansTamil Bold";
  src: url(../fonts/Tamil/NotoSansTamil-Bold.ttf) format("truetype");
}
