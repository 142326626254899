@import "~bootstrap/dist/css/bootstrap.min.css";
/*
:root {
  --mobile: 411px;
  --tablet: 768px;
  --laptop: 960px;
  --desktop: 1140px;
  --largeDesktop: 1440px;
}
*/

input {
  margin-top: 16px;
  font-size: 16px;
  font-family: "NotoSans Regular", sans-serif;
  border: 1px solid #c6cad4;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
}

select {
  margin-top: 20px;
  font-size: 16px;
  font-family: "NotoSans Regular", sans-serif;
  border: 1px solid #c6cad4;
  padding: 10px;
  border-radius: 8px;
  width: 100%;

  .select-placeholder {
    color: var(--C4);
  }
}

textarea {
  margin-top: 24px;
  font-size: 16px;
  color: var(--C26);
  background-color: var(--C9);
  font-family: "NotoSans Regular", sans-serif;
  border: 1px solid #b8bdc8;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
}

button {
  margin-top: 40px;
  background-color: var(--C8);
  color: #495a95;
  border: 2px solid #495a95;
  border-radius: 8px;
  padding: 15px 0px;
  width: 180px;
  cursor: pointer;
}
